<template>
  <WeCard class="border-radius-9">
    <div class="row align-items-center mb-3">
      <div class="col">
        <h5 class="mb-0">Çok Satan Ürünler</h5>
      </div>
      <div class="col-auto">
        <div class="btn-group">
          <span
            class="btn btn-sm btn-light"
            v-bind:class="{ active: productType == btn.id }"
            v-for="btn in filterButtons"
            v-bind:key="btn.id"
            v-on:click="changeFilterType(btn.id)"
            >{{ btn.name }}</span
          >
          <span class="btn btn-light btn-sm" v-on:click="onRefresh">
            <i class="fas fa-sync"></i>
          </span>
        </div>
      </div>
    </div>

    <hr />

    <div class="position-relative" style="min-height: 350px" v-if="loading">
      <WeLoading />
    </div>
    <ul class="list-group" v-else-if="data.length">
      <li
        class="list-group-item border-0 hover-shadow mb-1 border-radius-9"
        v-for="(product, index) in data"
        v-bind:key="product.id"
        v-bind:class="{ 'bg-light': index % 2 == 1 }"
      >
        <div class="row align-items-center">
          <div class="col-auto text-center" style="min-width: 50px">
            <h6 class="mb-0 text-muted">{{ 1 * index + 1 }}</h6>
          </div>
          <div class="col">
            <h6 class="mb-0">{{ product.name }}</h6>
            <!-- <span>{{ product.price | moneyFormat }}</span> -->
          </div>
          <div class="col-auto">
            <template v-if="productType == 'quantity'">
              <span class="font-weight-bold">{{ product.sale_quantity }} </span>
              {{ product.quantity_type.name }}
            </template>
            <template v-else>
              <span class="font-weight-bold"
                >{{ product.sale_price | moneyFormat }}
              </span>
            </template>
          </div>
        </div>
      </li>
    </ul>
    <WeCard class="alert-primary" v-else>
      <i class="fas fa-info-circle"></i> <span v-html="getInfo"></span>
    </WeCard>
  </WeCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Product",
  data() {
    return {
      loading: false,
      productType: this.type,
      filterButtons: [
        {
          id: "quantity",
          name: "Adet",
        },
        {
          id: "price",
          name: "Fiyat",
        },
      ],
      data: [],
    };
  },
  props: {
    type: {
      default: "quantity",
    },
  },
  methods: {
    ...mapActions("widget", ["refreshReport"]),
    changePeriod(data) {
      this.period = data;
      this.onRefresh();
    },
    onRefresh() {
      this.loading = true;
      this.refreshReport({
        name: "bestSellerProducts",
        params: {
          type: this.productType,
          period: this.period,
        },
        onSuccess: (result) => {
          let items = result.data.items || [];

          if (items && items.length) {
            items.map((item) => {
              item.sale_quantity = new Intl.NumberFormat("tr-TR").format(
                item.sale_quantity
              );
              return item;
            });
          }

          this.data = items;
          this.loading = false;
        },
      });
    },
    changeFilterType(id) {
      this.productType = id;
      this.onRefresh();
    },
  },
  computed: {
    ...mapState({
      report: (state) => state.widget.reports,
    }),
    getInfo() {
      if (!this.data.length) {
        let period = this.report.periodFilters.find(
          (item) => item.id == this.report.period
        );
        if (period) {
          return `<b>${period.name}</b> için listede ürün bulunmamaktadır.`;
        }
      }
    },
  },
  mounted() {
    this.onRefresh();
  },
  watch: {
    "report.period": function (newVal) {
      if (newVal && newVal !== "custom_date") {
        this.onRefresh(true);
      }
    },
    "report.periodDate": function (newVal) {
      if (newVal && newVal.start && newVal.end) {
        this.onRefresh(true);
      }
    },
  },
};
</script>
